/* Generic heading section styling */
.promo-section {
    color: #fff;
    height: 680px;
    padding-bottom: 0;
    padding-top: 280px;
}

.promo-section {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/software/hero-bg-1.jpg") no-repeat 50% top;
    background-size: cover;
}

@media (max-width: 767px) {
    .promo-section {
        padding-top: 130px;
        text-align: center;
        height: 400px;
    }

    .overview-section {
        margin-top: 2.5rem;
    }
}

.item-icon {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 10px;
    display: inline-block;
}
