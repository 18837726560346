@import "../scss/variables";

$primary1: #48A679;
$gradient-color: rgb(139, 177, 116);
$gradient-color-opacity: rgba(139, 177, 116, 0.9);
$primary-hover-color: #3f8965;

.horizontalMenu>.horizontalMenu-list>li> {
    ul.sub-menu>li> {
        a.active,
        ul.sub-menu>li>a.active {
            color: $primary1;
        }
    }
    .horizontal-megamenu .link-list li a.active {
        color: $primary1;
    }
}

.bg-background2:before,
.bg-background1:before {
    background: linear-gradient(-225deg, rgba(10, 14, 14, 0.6) 48%, rgba(10, 14, 14, 0.6) 100%);
}

a:hover {
    color: $primary1;
}

.pattern:before,
.pattern1:before {
    background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%) !important;
}

.owl-controls .owl-page.active {
    border-color: $primary1;
    opacity: 1;
    &:hover {
        border-color: $primary1;
        opacity: 1;
    }
}

.owl-carousel:hover .owl-nav button,
.owl-nav button {
    color: $primary1 !important;
}

.item-card7-overlaytext h4 {
    background: $primary1;
}

.btn-primary {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
    &:hover {
        color: $white;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.5);
    }
    &.disabled,
    &:disabled {
        color: $white;
        background-color: $primary1;
        border-color: $primary1;
    }
    &:not(:disabled):not(.disabled) {
        &:active,
        &.active {
            color: $white;
            background-color: $primary1;
            border-color: $primary1;
        }
    }
}

.show>.btn-primary.dropdown-toggle {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
}

.btn-primary:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.1);
    }
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.1);
}

.btn-outline-primary {
    color: $primary1;
    background-color: transparent;
    background-image: none;
    border-color: $primary1 !important;
    &:hover {
        color: $white;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color !important;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.1);
    }
    &.disabled,
    &:disabled {
        color: $primary1;
        background-color: transparent;
    }
    &:not(:disabled):not(.disabled) {
        &:active,
        &.active {
            color: $white;
            background-color: $primary1;
            border-color: $primary1;
        }
    }
}

.show>.btn-outline-primary.dropdown-toggle {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.1);
    }
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.1);
}

.btn-link {
    color: $primary1;
}

.alert-primary {
    color: #fcfdff;
    background-color: $primary1;
    border-color: $primary1;
    hr {
        border-top-color: #d8f3f2;
    }
    .alert-link {
        color: #172b46;
    }
}

.label-primary {
    background-color: $primary1;
}

.badge-primary {
    color: $white;
    background-color: $primary1;
    &[href] {
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
            background-color: $primary1;
            box-shadow: none;
        }
    }
}

.bg-gradient-primary {
    // background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
    background: linear-gradient(86deg, rgba(8, 158, 96, 0.8) 0%, rgba(19, 150, 204, 0.5) 100%);
}

.bg-primary {
    background-color: $primary1 !important;
}

a.bg-primary {
    &:hover,
    &:focus {
        background-color: $primary-hover-color !important;
    }
}

button.bg-primary {
    &:hover,
    &:focus {
        background-color: $primary-hover-color !important;
    }
}

.border-primary {
    border-color: $primary1 !important;
}

.text-primary {
    color: $primary1 !important;
}

a {
    &.text-primary {
        &:hover,
        &:focus {
            color: $primary-hover-color !important;
        }
    }
    &.text-dark {
        &:hover,
        &:focus {
            color: $primary1 !important;
        }
    }
}

.social-box.linkedin i {
    background: $primary1;
}

.checkbox span:after,
.user-card .card-block .activity-leval li.active {
    background-color: $primary1;
}

#count-down .clock-presenter .digit,
.notifyimg {
    background: $primary1;
}

.drop-icon-wrap .drop-icon-item:hover {
    color: $primary1;
}

.dropdown-item {
    &:hover,
    &:focus,
    &.active,
    &:active {
        color: $primary1;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    color: $white;
    border-color: $primary1;
    background-color: $primary1;
}

.custom-checkbox .custom-control-input {
    &:checked~.custom-control-label::before,
    &:indeterminate~.custom-control-label::before {
        background-color: $primary1;
    }
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: $primary1;
}

.custom-select:focus {
    border-color: $primary1;
}

.custom-file-input:focus~.custom-file-label {
    border-color: $primary1;
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.25);
    &::after {
        border-color: $primary1;
        box-shadow: none;
    }
}

.custom-file-label::after {
    background-color: $primary1;
    border-left: 1px solid $primary1;
}

.custom-range {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb {
        background-color: $primary1;
    }
}

.nav-link.active {
    background-color: $primary1;
}

.nav-pills {
    .nav-link.active,
    .show>.nav-link {
        background-color: $primary1;
    }
}

.page-link:hover {
    color: $primary1;
}

.page-item.active .page-link {
    background-color: $primary1;
    border-color: $primary1;
}

.range.range-primary {
    input[type="range"] {
        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
            background-color: $primary1;
        }
    }
    output {
        background-color: $primary1;
    }
    input[type="range"] {
        outline-color: $primary1;
    }
}

.nav-tabs .nav-item1 .nav-link {
    &:hover:not(.disabled),
    &.active {
        background: $primary1;
    }
}

.panel.price>.panel-heading {
    background: $primary1;
    color: $white;
}

.heading-primary {
    background-color: $primary1;
}

.page-header .breadcrumb-item a {
    color: #051441;
}

.breadcrumb-item1 a:hover,
.panel-title1 a {
    color: $primary1;
}

.header {
    background: $white;
}

.header-bg {
    background: #eef2f9;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #eef2f9, $primary1);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #eef2f9, $primary1);
}

.nav-tabs .nav-link {
    &:hover:not(.disabled),
    &.active {
        color: $white;
        background: $primary1;
    }
}

.navtab-wizard.nav-tabs .nav-link {
    &.active,
    &:hover {
        color: $primary1;
        background: $white;
    }
}

.admin-navbar {
    .nav-item.active .nav-link {
        color: $primary1;
        .icon {
            color: $primary1;
        }
    }
    .nav-link {
        &:hover,
        &:focus,
        &.active {
            color: $primary1;
        }
    }
    .mega-dropdown .sub-item .section-label {
        color: $primary1;
    }
    .sub-item ul a {
        &:active {
            background-color: $primary1;
        }
        &:hover,
        &:focus {
            color: $primary1;
            text-decoration: none;
            background-color: #f9faff;
        }
    }
}

.expanel-primary>.expanel-heading {
    color: $white !important;
    background-color: $primary1 !important;
    border-color: $primary1 !important;
}

.error-img .form-control:focus {
    color: $white;
    background-color: $white-2;
    border-color: $primary1;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.25);
}

.avatar {
    background: $primary1;
    no-repeat: center/cover;
}

.spinner {
    background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
}

.spinner-lg {
    background-color: $primary1;
}

.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
    background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
}

.lds-heart div {
    background: $primary1;
    &:after,
    &:before {
        background: $primary1;
    }
}

.lds-ring div {
    border: 6px solid $primary1;
    border-color: $primary1;
}

.lds-hourglass:after {
    border: 26px solid $primary1;
    border-color: $primary1;
}

.mediaicon {
    border: 1px solid $primary1;
    background: $primary1;
}

a.chip:hover {
    background-color: $primary1;
}

.highlight .s {
    color: $primary1;
}

.selectgroup-input {
    &:checked+.selectgroup-button {
        border-color: $primary1;
        z-index: 1;
        color: $primary1;
        background: #f6f7fb;
    }
    &:focus+.selectgroup-button {
        border-color: $primary1;
        z-index: 2;
        color: $primary1;
        box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.25);
    }
}

.custom-switch-input {
    &:checked~.custom-switch-indicator {
        background: $primary1;
    }
    &:focus~.custom-switch-indicator {
        box-shadow: none;
        border-color: $primary1;
    }
}

.imagecheck-input:focus~.imagecheck-figure {
    border-color: $primary1;
    box-shadow: none;
}

.imagecheck-figure:before {
    background: $primary1 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
    color: $white;
}

.colorinput-input:focus~.colorinput-color {
    border: 1px solid $white-1;
    box-shadow: none;
}

.ui-datepicker .ui-datepicker-title {
    color: $primary1;
}

#back-to-top {
    background: $primary1;
    &:hover {
        background: $white;
        color: $primary1;
        border: 2px solid $primary1;
    }
}

#chat-message {
    background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
}

.tabs-menu ul li .active {
    background: $primary1;
}

.tabs-menu1 ul li .active {
    border: 1px solid $primary1;
    color: $primary1;
}

.wideget-user-tab .tabs-menu1 ul li .active {
    border-bottom: 2px solid $primary1 !important;
}

.message-feed:not(.right) .mf-content {
    background: $primary1;
    color: $white;
    &:before {
        border-right-color: $primary1;
    }
}

.msb-reply button {
    background: $primary1;
}

.wizard-card {
    .moving-tab {
        background-color: $primary1 !important;
    }
    .choice {
        &:hover .icon,
        &.active .icon {
            border-color: $primary1 !important;
            color: $primary1 !important;
        }
    }
}

.cal1 .clndr {
    .clndr-table {
        .header-days {
            background: $primary1;
        }
        tr .day {
            &.today.event,
            &.my-today.event {
                background: $primary1;
            }
            &.today:hover,
            &.my-today:hover {
                background: $primary1;
                color: $white;
            }
        }
    }
    .clndr-controls .clndr-control-button {
        .clndr-previous-button {
            color: $primary1;
            background: #e0f5f5;
        }
        .clndr-next-button {
            color: $primary1;
            background: #e0f5f5;
            &:hover {
                background: $primary1;
            }
        }
        .clndr-previous-button:hover {
            background: $primary1;
        }
    }
}

.fc button {
    background: $primary1;
}

.fc-event,
.fc-event-dot {
    background-color: $primary1;
    color: #f1f1f1 ! important;
}

#sidebar {
    .accordion-toggle i {
        background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
    }
    ul li.active>a {
        color: $primary1;
        &:hover {
            color: $primary1;
        }
    }
    li a[aria-expanded="true"].active:before {
        background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
    }
    ul li a:hover,
    .collapse li a:hover {
        color: $primary1;
    }
}

.sweet-alert button {
    background-color: $primary1 !important;
}

.timeline__item:after {
    border: 6px solid $primary1;
}

.label-primary.arrowed:before {
    border-right-color: $primary1;
}

.widgetdate p,
.widgetbox p,
.datebox p {
    border-bottom: 2px solid $primary1;
}

.arrow-ribbon.bg-primary:before {
    border-left: 15px solid $primary1;
}

.arrow-ribbon2:before {
    border-left: 24px solid $primary1;
}

.badge-offer.bg-primary:after,
.badge-offer1.bg-primary:after {
    border-top: 12px solid $primary1;
}

.social-icons li:hover {
    background: $primary1;
    color: $white;
}

.social li a:hover,
.footer-main a:hover,
.footer-links a:hover {
    color: $primary1;
}

.product-tags li a:hover {
    background: $primary1;
    border-color: $primary1;
}

.info .counter-icon {
    border: 1px solid $white-6;
    i {
        color: $white;
    }
}

.counter-icon {
    border: 1px solid $primary1;
    i {
        color: $primary1;
    }
}

.header-links li a {
    &.active,
    &:hover {
        background: $primary1;
        color: $white;
    }
}

.card-pay .tabs-menu li a.active,
.item1-tabs-menu ul li .active {
    background: $primary1;
}

.items-blog-tab-heading .items-blog-tab-menu li .active {
    background: $primary1;
    color: $white;
}

.item2-gl-nav .item2-gl-menu li .active {
    color: $primary1;
}

.item-all-cat {
    .item-all-card:hover .item-all-text h5 {
        color: $primary1 !important;
    }
    .category-type .item-all-card img {
        background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
    }
}

.carousel-indicators li.active {
    color: $white;
    background: $primary1;
}

.item-cards7-ic.realestate-list li a:hover {
    color: $primary1;
}

.item1-links a {
    &:hover {
        color: $primary1;
    }
    &.active {
        color: $primary1;
        border-right: 2px solid $primary1;
    }
}

.settings-tab .tabs-menu li a.active {
    background: rgba(14, 53, 53, 0.1);
    color: $primary1;
}

.ads-tabs .tabs-menus ul li .active {
    background: $primary1;
    color: $white;
    border: 1px solid transparent;
}

.showmore-button-inner {
    border: 1px solid $primary1;
    color: $primary1;
}

.owl-nav>div i {
    color: $primary1;
}

.tabs-menu ul.eductaional-tabs li .active {
    background: $primary1;
}

.register-right .nav-tabs .nav-link {
    &:hover,
    &.active {
        color: $primary1;
        background: transparent;
        border: 1px solid $primary1;
    }
}

.gradient-icon {
    background: linear-gradient(120deg, #2ddcd3 0%, $primary1 100%);
}

.axgmap-img {
    border: 3px solid $primary1;
}

.horizontalMenu>.horizontalMenu-list>li {
    > {
        .horizontal-megamenu .menu_form input {
            &[type="submit"],
            &[type="button"] {
                background-color: $primary1;
                color: $white;
            }
        }
        a.active {
            background-color: $white-2;
            color: $white;
        }
    }
    &:hover>a {
        background: transparent;
        color: $primary1;
        .fa {
            color: $white !important;
        }
    }
    >ul.sub-menu>li {
        &:hover>a {
            background-color: transparent;
            color: $primary1;
        }
        >ul.sub-menu>li {
            &:hover>a,
            >ul.sub-menu>li:hover a {
                background-color: transparent;
                color: $primary1;
            }
        }
    }
}

code {
    color: $primary1;
}

#gdpr-cookie-message {
    h4,
    h5 {
        color: $primary1;
    }
    a {
        color: $primary1;
        border-bottom: 1px solid $primary1;
        &:hover {
            border-bottom: 1px solid $primary1;
            transition: all 0.3s ease-in;
            color: $primary1;
        }
    }
}

button#gdpr-cookie-advanced {
    color: $white;
    background-color: $primary1 !important;
}

.ui-widget-header,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: $primary1;
}

.app-sidebar {
    .side-menu li {
        &.active>a,
        a.active {
            color: $primary1;
        }
    }
    .slide-menu li.active>a {
        color: $primary1;
    }
    .side-menu li {
        ul li a:hover,
        a:hover {
            color: $primary1;
        }
    }
}

.form-inline .nav-search .btn {
    border: 2px solid $primary1;
}

.wrapper>h1 span {
    border-bottom: 2px solid $primary1;
    color: $primary1;
}

.tab_wrapper {
    >ul li.active {
        border-color: $primary1;
        background: $primary1;
        color: $white;
    }
    .controller span {
        background: $primary1;
    }
    .content_wrapper .accordian_header.active {
        color: $primary1;
        &:after {
            background: $primary1;
        }
        .arrow {
            border-top: 3px solid $primary1;
            border-left: 3px solid $primary1;
        }
    }
    &.left_side>ul li.active:after,
    &.right_side>ul li.active:after {
        background: $primary1;
    }
}

.addui-slider .addui-slider-track {
    .addui-slider-range,
    .addui-slider-handle:after {
        background: $primary1;
    }
}

.accordionjs .acc_section.acc_active>.acc_head {
    background: $primary1;
}

.perfect-datetimepicker tbody td.selected {
    border: 1px solid $primary1;
    background-color: $primary1;
}

div.conv-form-wrapper div {
    &.options div.option {
        border: 1px solid $primary1;
        color: $primary1;
    }
    &#messages div.message.from {
        background: $primary1;
    }
    &.options div.option.selected {
        background: $primary1;
        color: $white;
    }
}

form.convFormDynamic button.submit {
    border: 1px solid $primary1;
    background: $primary1;
    &:hover {
        background: $primary1;
        color: $white;
    }
}

.exzoom {
    .exzoom_nav .exzoom_nav_inner span.current {
        border: 1px solid $primary1;
    }
    .exzoom_btn a {
        /*border: 1px solid $primary1;*/
        color: $primary1;
    }
}

.prev:hover {
    transform: translate(-5px, 0px);
    color: $primary1 !important;
}

.next:hover {
    color: $primary1 !important;
}

.g_f-s {
    .prev:hover i {
        transform: translate(-5px, 0px);
        color: $primary1;
    }
    .next:hover i {
        transform: translate(5px, 0px);
        color: $primary1;
    }
    .close-button>*:hover {
        color: $primary1;
    }
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover {
    color: $primary1;
}

.register {
    background: linear-gradient(86deg, rgb(1, 130, 129) 0%, rgb(14, 53, 53) 100%);
}

.btnRegister {
    background: $primary1;
}

.register .nav-tabs .nav-link.active {
    color: $primary1;
    border: 1px solid $primary1;
}

.pretty {
    input:checked~.state.p-primary label:after,
    &.p-toggle .state.p-primary label:after {
        background-color: $primary1 !important;
    }
    input:checked~.state.p-primary-o label:before,
    &.p-toggle .state.p-primary-o label:before {
        border-color: $primary1;
    }
    input:checked~.state.p-primary-o {
        .icon1,
        .svg,
        svg {
            color: $primary1;
            stroke: $primary1;
        }
    }
    &.p-toggle .state.p-primary-o {
        .icon1,
        .svg,
        svg {
            color: $primary1;
            stroke: $primary1;
        }
    }
    &.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after {
        background-color: $primary1 !important;
    }
    &.p-switch {
        input:checked~.state.p-primary:before {
            border-color: $primary1;
        }
        &.p-fill input:checked~.state.p-primary:before {
            background-color: $primary1 !important;
        }
    }
}

.owl-carousel button.owl-dot.active {
    background: $primary1 !important;
}

.bg-background:before {
    background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%);
}

.bg-background-color:before {
    background: linear-gradient(86deg, rgba(8, 158, 96, 0.8) 0%, rgba(19, 150, 204, 0.7) 100%) !important;
}

.bg-background3:before,
.bg-background-5:before {
    background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%);
}

.bg-background-6:before,
.slider-header .item:before {
    background: linear-gradient(-225deg, rgba(10, 14, 14, 0.6) 48%, rgba(10, 14, 14, 0.6) 100%);
}

.slider-images {
    .slider-img1:before,
    .slider-img2:before,
    .slider-img3:before {
        background: linear-gradient(-225deg, rgba(10, 14, 14, 0.6) 48%, rgba(10, 14, 14, 0.6) 100%);
    }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.25);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(14, 53, 53, 0.25);
}

.custom-range {
    &::-webkit-slider-thumb:focus,
    &::-moz-range-thumb:focus,
    &::-ms-thumb:focus {
        outline: none;
        box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(14, 53, 53, 0.25);
    }
}

.selectize-input.focus {
    border-color: $blue;
    box-shadow: 0 0 0 2px rgba(14, 53, 53, 0.25);
}

.item-card2-img:hover a {
    background: transparent;
}

.app-sidebar {
    .side-menu__item.active {
        background: $white !important;
        color: $primary1 !important;
        &:hover {
            background: $white !important;
            color: $primary1 !important;
        }
    }
    &:before {
        background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%);
    }
}

.app-sidebar-footer {
    background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%);
}

@media (max-width: 992px) {
    .search-show .nav-search {
        background: linear-gradient(to right, rgb(14, 53, 53), rgb(14, 53, 53));
    }
}

@media (max-width: 992px) {
    .nav-item.with-sub .sub-item {
        border-top: 2px solid $primary1;
    }
}

@media (max-width: 480px) {
    .zoom-container .arrow-ribbon2:before {
        border-top: 17px solid transparent;
        border-left: 17px solid $primary1;
        border-bottom: 17px solid transparent;
    }
}

.bg-secondary {
    background-color: $secondary1 !important;
}

a.bg-secondary {
    &:hover,
    &:focus {
        background-color: $secondary1 !important;
    }
}

button.bg-secondary {
    &:hover,
    &:focus {
        background-color: $secondary1 !important;
    }
}

.text-secondary {
    color: $secondary1 !important;
}

a.text-secondary {
    &:focus,
    &:hover {
        color: $secondary1 !important;
    }
}

.btn-secondary {
    color: $white;
    background-color: $secondary1;
    border-color: $secondary1;
    &:hover {
        color: $white;
        background-color: #982168;
        border-color: #982168;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(167, 32, 113, 0.5);
    }
    &.disabled,
    &:disabled {
        color: $white;
        background-color: $secondary1;
        border-color: $secondary1;
    }
    &:not(:disabled):not(.disabled) {
        &:active,
        &.active {
            color: $white;
            background-color: $secondary1;
            border-color: $secondary1;
        }
    }
}

.show>.btn-secondary.dropdown-toggle {
    color: $white;
    background-color: $secondary1;
    border-color: $secondary1;
}

.btn-secondary:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(167, 32, 113, 0.5);
    }
}

.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(167, 32, 113, 0.5);
}

.footer-style2 .footer-main li:hover a,
.header-main i {
    color: $primary1;
}

.item-card {
    .item-card-desc:before {
        background: rgba(40, 35, 47, 0.5);
    }
    // &:hover .item-card-desc:before {
    //     background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%) !important;
    // }
}

@media (min-width: 992px) {
    .sticky-wrapper.is-sticky {
        .horizontalMenu>.horizontalMenu-list>li>a.active {
            background-color: $white;
            color: $primary1;
        }
        .horizontal-main {
            box-shadow: 0 0 0 1px rgba(162, 169, 204, 0.12), 0 8px 16px 0 rgba(162, 169, 204, 0.24);
        }
    }
}

.item-card7-imgs:hover a {
    background: transparent;
}

.sticky-wrapper.relative.banners .horizontalMenu>.horizontalMenu-list>li {
    >a.active,
    &:hover>a {
        background-color: $primary1;
    }
}

.pricingTable2.pink .pricing-plans {
    background-color: $primary1;
}

.construction-image:before {
    background: linear-gradient(-225deg, rgba(14, 53, 53, 0.8) 48%, rgba(14, 53, 53, 0.8) 100%);
}

.construction {
    z-index: 1;
}

.countdown-timer-wrapper .timer .timer-wrapper .time {
    color: $primary1;
    background: rgb(255, 255, 255);
}

.pricingTable {
    &:hover .pricingTable-signup {
        background: $primary1;
        color: $white;
    }
    .pricingTable-signup {
        background: rgba(14, 53, 53, 0.12);
        color: $primary1;
    }
    &:hover .title {
        background: $primary1;
        color: $white;
    }
    &.advance-pricing {
        border: 1px solid $primary1;
    }
    &:hover {
        border: 1px solid $primary1;
        .title {
            &:before {
                border-right-color: $primary1;
            }
            &:after {
                border-left-color: $primary1;
            }
        }
    }
    .title {
        &:before {
            content: "";
            border-right: 26px solid rgba(14, 53, 53, 0.1);
        }
        &:after {
            content: "";
            border-right: 26px solid rgba(14, 53, 53, 0.1);
            border-right: none;
            border-left: 26px solid rgba(14, 53, 53, 0.1);
        }
        background: rgba(14,
        53,
        53,
        0.1);
        color: $primary1;
    }
}

@media (max-width: 991px) {
    .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: $primary1;
        color: $white;
    }
}

.bg-card-light {
    &.bg-primary-card:hover {
        background: $primary1 !important;
    }
    &.bg-secondary-card:hover {
        background: $secondary1 !important;
    }
}

.status-border:hover {
    border: 1px solid $primary1 !important;
    i {
        color: $primary1 !important;
    }
}

.bg-card:hover .cat-desc {
    color: $primary1 !important;
}

.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
    background-color: $primary1;
    color: $white !important;
}

.btn-appointment a:hover {
    background: $primary1;
    color: $white !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $primary1;
    color: $white;
}

.owl-controls .owl-page.active {
    border-color: $secondary1;
    opacity: 1;
    &:hover {
        border-color: $secondary1;
        opacity: 1;
    }
}

.horizontalMenu>.horizontalMenu-list>li>a.active {
    background: transparent;
    color: $primary1;
}

.owl-controls .owl-page.active:before {
    border-top-color: $secondary1;
}

.badge-secondary {
    color: $white;
    background-color: $secondary1;
    &[href] {
        &:focus,
        &:hover {
            color: $white;
            text-decoration: none;
            background-color: $secondary1;
            box-shadow: none;
        }
    }
}

.pricingTable2.blue .pricing-plans {
    background-color: $secondary1;
}

.pattern-1 {
    background: linear-gradient(rgba(1, 130, 129, 0.7), rgba(14, 53, 53, 0.7)), url(../images/media/photos/pattern.jpg) center;
}

.pattern-2 {
    background: linear-gradient(86deg, rgba(1, 130, 129, 0.7) 0%, rgba(14, 53, 53, 0.7) 100%), url(../images/banners/subscribe.jpg);
}

.bg-secondary-transparent {
    background-color: rgba(167, 32, 113, 0.1);
}

.bg-primary-transparent {
    background-color: rgba(14, 53, 53, 0.1);
}

.card-blog-img:before {
    background: rgba(14, 53, 53, 0.6);
}

.breadcrumb-item.active {
    color: $primary1;
}

.label-secondary {
    background-color: $secondary1;
}

.footer-style2 .accent-2 {
    border-top: 2px solid $primary1;
}

.btn-outline-secondary {
    color: $secondary1;
    background-color: transparent;
    background-image: none;
    border-color: $secondary1;
    &:hover {
        color: $white;
        background-color: $secondary1;
        border-color: $secondary1;
    }
    &.focus,
    &:focus {
        box-shadow: 0 0 0 1px rgba(167, 32, 113, 0.1);
    }
    &.disabled,
    &:disabled {
        color: $secondary1;
        background-color: transparent;
    }
    &:not(:disabled):not(.disabled) {
        &.active,
        &:active {
            color: $white;
            background-color: $secondary1;
            border-color: $secondary1;
        }
    }
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: $white;
    background-color: $secondary1;
    border-color: $secondary1;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
    &.active:focus,
    &:active:focus {
        box-shadow: 0 0 0 1px rgba(167, 32, 113, 0.1);
    }
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(167, 32, 113, 0.1);
}

.table-secondary {
    background-color: $secondary1;
    > {
        td,
        th {
            background-color: $secondary1;
        }
    }
}

.table-hover .table-secondary:hover {
    background-color: #982168;
    > {
        td,
        th {
            background-color: #982168;
        }
    }
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
    background: $secondary1;
}

.pop-primary {
    color: $primary1;
}

.popsecondary {
    color: $secondary1;
}

.alert-secondary {
    color: $white;
    background-color: $secondary1;
    border-color: $secondary1;
    hr {
        border-top-color: $secondary1;
    }
    .alert-link {
        color: $secondary1;
    }
}

.list-group-item-secondary {
    color: $secondary1;
    background-color: #f5cdd7;
    &.list-group-item-action {
        &:focus,
        &:hover {
            color: $secondary1;
            background-color: #f5cdd7;
        }
        &.active {
            color: $white;
            background-color: $secondary1;
            border-color: $secondary1;
        }
    }
}

.border-secondary {
    border-color: $secondary1 !important;
}

.expanel-secondary>.expanel-heading {
    color: $white !important;
    background-color: $secondary1 !important;
    border-color: $secondary1 !important;
}

.tag-primary {
    background-color: $primary1;
    color: $white;
}

.tag-secondary {
    background-color: $secondary1;
    color: $white;
}

.badge-offer.bg-secondary:after,
.badge-offer1.bg-secondary:after {
    border-top: 12px solid $secondary1;
}

.arrow-ribbon.bg-secondary:before {
    border-left: 15px solid $secondary1;
}

.table-primary {
    background-color: $primary1;
    color: $white;
    >td,
    th {
        background-color: $primary1;
        color: $white;
    }
}

.table-hover .table-primary:hover {
    background-color: $primary-hover-color;
    > {
        td,
        th {
            background-color: $primary-hover-color;
        }
    }
}

.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
    border-color: $primary1 !important;
}

.list-group-item-primary {
    color: $primary1;
    background-color: #e0f5f5;
}

.list-group-flush .list-group-item:hover {
    border: 1px solid $primary1;
}

.list-group-item-primary.list-group-item-action {
    &:focus,
    &:hover {
        color: $primary1;
        background-color: #e0f5f5;
    }
    &.active {
        color: $white;
        background-color: #e0f5f5;
        border-color: #e0f5f5;
    }
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
    background: $primary1;
    border-color: $primary1;
    &:before {
        border-color: $primary1;
    }
}

.onoffswitch2-checkbox:checked+ {
    .onoffswitch2-label {
        background: $primary1;
    }
    .onoffswitch-label2,
    .onoffswitch2-label:before {
        border-color: $primary1;
    }
}

.banner-2 .search-background .btn-orange {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
    &:hover {
        color: $white;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
    }
    &.focus,
    &:focus {
        box-shadow: 0 0 0 1px rgba(14, 53, 53, 0.5);
    }
}

.slider-images .search-background .btn-dark,
#main .search-background .btn-dark,
.banner-2 .search-background .btn-dark,
.banner-1 .search-background .btn-dark {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
}

.slider-images .search-background .btn-dark:hover,
#main .search-background .btn-dark:hover,
.banner-2 .search-background .btn-dark:hover,
.banner-1 .search-background .btn-dark:hover {
    color: $white;
    background-color: $primary-hover-color;
    border-color: $primary-hover-color;
}

.slider-images .search-background .btn-dark.focus,
#main .search-background .btn-dark.focus,
.banner-1 .search-background .btn-dark.focus,
.banner-2 .search-background .btn-dark.focus,
.slider-images .search-background .btn-dark:focus,
.banner-1 .search-background .btn-dark:focus,
.banner-2 .search-background .btn-dark:focus,
#main .search-background .btn-dark:focus {
    box-shadow: 0 0 0 1px rgba(14, 53, 53, 0.5);
}

.sptb-1.bg-background .search-background .btn-dark {
    color: $white;
    background-color: $secondary1;
    border-color: $secondary1;
    &:hover {
        color: $white;
        background-color: #982168;
        border-color: #982168;
    }
    &.focus,
    &:focus {
        box-shadow: 0 0 0 1px rgba(167, 32, 113, 0.5);
    }
}

.banner-top .banner-height .banner-text .btn-orange {
    color: $white;
    background-color: $primary1;
    border-color: $primary1;
    &:hover {
        color: $white;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
    }
    &.focus,
    &:focus {
        box-shadow: 0 0 0 1px rgba(14, 53, 53, 0.5);
    }
}

.header-style1 .horizontal-main {
    background: #1b1d23b0;
}

.header-search-logo .header-brand-img {
    background: url(../images/brand/logos/logo-1.png);
    display: block;
    width: 150px;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: 11px;
}

.color-header {
    .top-bar {
        background: $primary1;
    }
    .header-main .top-bar li a.text-dark i {
        color: $white;
    }
}

.dark-horizontalmenu .horizontalMenu>.horizontalMenu-list>li {
    >a.active,
    &:hover>a {
        background: #232a40;
    }
}

.color-horizontalmenu .horizontal-main {
    background: $primary1;
}

@media only screen and (max-width: 991px) {
    .smllogo.mobile-logo {
        background: url(../images/brand/logos/logo-1.png);
        width: 150px;
        height: 40px;
        background-repeat: no-repeat;
        margin: 0 auto;
        text-align: center;
        margin-top: 8px !important;
    }
    .color-horizontalmenu .horizontalMenu>.horizontalMenu-list {
        background: $primary1;
    }
}

.my-dash.app-sidebar .side-menu li a.side-menu__item.active {
    background: transparent;
    color: $primary1;
    border-right: 2px solid $primary1;
}