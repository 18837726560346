.counter {
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.counter-icon {
    margin-bottom: 1rem;
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    padding: 1.3rem 1.4rem;
    border-radius: 50%;
    text-align: center;
    i {
        font-size: 1.2rem;
    }
}

.counter-1 {
    font-size: 40px;
    font-weight: 600;
}

.socials {
    display: flex;
    li {
        margin: 0 12px;
        margin-right: 15px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .7;
    z-index: 1;
}


@media (min-width: 992px) {
    .sticky-wrapper.is-sticky {
        .horizontal-main .ad-post {
            margin-top: -0.25rem !important;
        }
        .horizontalMenu>.horizontalMenu-list>li>a.active {
            background-color: #e67605;
            color: $white;
            .fa {
                color: $white !important;
            }
        }
    }

    .sticky-wrapper.is-sticky .desktoplogo {
        display: none;
    }
}

#global-loader {
    position: fixed;
    z-index: 50000;
    background: $white;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.loader-img {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 45%;
    margin: 0 auto;
}

.close {
    float: right;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #a0afc7;
    text-shadow: 0 1px 0 $white;
    opacity: .7;
    transition: .3s color;
    &:focus,
    &:hover {
        color: #a0afc7;
        text-decoration: none;
        opacity: .75;
    }
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

@media (max-width: 576px) {
    .header-text h1 {
        font-size: 2rem;
    }
    .section-title h2 {
        font-size: 1.5rem;
    }
    .content-text .display-5 {
        font-size: 1.8rem;
    }
    .banner-section .header-text h1,
    .header-text1 .text-property h1 {
        font-size: 2rem !important;
    }
}

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid #ced8e6;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.bannerimg .breadcrumb {
    display: inline-flex;
}

.icon i {
    vertical-align: -1px;
}

a.icon {
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: #1a1a1a !important;
    }
}

.o-auto {
    overflow: auto !important;
}

.o-hidden {
    overflow: hidden !important;
}

.shadow {
    box-shadow: 0 1px 2px 0 $black-05 !important;
}

.shadow-none {
    box-shadow: none !important;
}


@media (max-width: 480px) {
    .list-media .info {
        .text-right {
            display: none;
        }
        padding-bottom: 15px;
    }
    .tabs-menu ul li {
        width: 100%;
        margin: 5px 15px;
        a {
            width: 100%;
        }
    }
    .form-control.custom-select.w-auto {
        display: none;
    }
}

@media (max-width: 767px) {
    .header-brand {
        line-height: 2.7rem;
    }
    .header .input-icon.mt-2 {
        margin-top: 5px !important;
    }
    .footer .privacy {
        text-align: center !important;
    }
}

@media (max-width: 375px) {
    .nav-link span,

    .construction h3 {
        font-size: 2.8rem !important;
    }
}
@media (max-width: 990px) {
    .nav-tabs .nav-link {
        width: 100%;
    }
    .nav-tabs {
        z-index: 1000;
    }
}

@media (max-width: 320px) {
    .item-card9-footer .btn.btn-light {
        font-size: 11px;
    }
    .header-search .support-header h6 {
        font-size: 8px !important;
    }
    .list-inline.wizard {
        display: flex;
    }
}

svg {
    -ms-touch-action: none;
    touch-action: none;
}

#back-to-top {
    color: $white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    display: none;
    text-align: center;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    z-index: 10000;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    -webkit-transition: background-color .1s linear;
    -o-transition: background-color .1s linear;
    i {
        padding-top: 15px;
        font-size: 16px;
    }
}

.box-shadow-0 {
    box-shadow: none !important;
}

.p-15 {
    padding: 15px !important;
}


@media (max-width: 992px) {
    .search-background {
        background: 0 0;
        border-radius: 0;
        .form-group {
            margin-bottom: 10px !important;
            border-radius: 2px;
        }
    }
    dd {
        display: -webkit-box !important;
    }
    .header-search .header-nav .nav-cart .icon-cart span {
        display: none;
    }
    .header-nav .header-search-logo .header-logo .header-brand-img {
        height: auto;
        line-height: 2rem;
        vertical-align: bottom;
        margin-right: .5rem;
        width: auto;
    }
    .header-search .header-search-logo {
        text-align: center;
    }

    .item-single .item-single-desc .input-group {
        margin-bottom: 10px;
    }

    .header-style2 .top-bar,
    .header-style3 .top-bar {
        .top-bar-left .socials li {
            margin: 0 !important;
            padding: 10px !important;
        }
        padding: 0px !important;
    }

    .top-bar {
        text-align: center;
        .top-bar-left .contact {
            border-left: 0 !important;
            margin-left: 0 !important;
            padding-left: 0 !important;
            li {
                margin: 0 !important;
            }
        }
    }
    .item-search-menu ul li {
        text-align: center;
        border-radius: 2px;
        a {
            border-radius: 2px !important;
        }
    }
    .item-search-tabs {
        .form-group {
            &:last-child {
                margin-bottom: 0 !important;
            }
            margin-bottom: 10px !important;
        }
        .bg-white {
            background: 0 0 !important;
        }
        .tab-content {
            background: 0 0 !important;
            border: 0 !important;
        }
    }
    .item1-card {
        .item1-card-btn,
        .item1-card-info {
            margin-top: 15px;
        }
    }
    .items-blog-tab-heading .items-blog-tab-menu li {
        text-align: center;
        margin-bottom: 10px;
        a {
            display: block;
        }
    }
    .item2-gl .item2-gl-nav select,
    .item2-gl-nav h6 {
        text-align: center;
    }
    .item-card .item-card-img img,
    .item-card2-img img,
    .item-card9-img img,
    .item-card4-img img,
    .item-card5-img img,
    .item-card7-img img,
    .item-card8-img img,
    .overview .overview-img img {
        width: 100%;
    }

    .item-user-icons {
        margin-left: 10px;
    }
    .item1-card-tab .item1-tabs-menu ul li {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .user-wideget h6 {
        margin-top: 5px;
    }
    .header-main .top-bar {
        .contact,
        &.p-3 .contact {
            display: none;
        }
    }
    .bannerimg .header-text h1 {
        display: block;
        margin-bottom: 0rem !important;
        font-size: 2rem !important;
        line-height: 1;
    }

    .item-card9-desc a,
    .item-card2-footer a,
    .item7-card-desc a {
        font-size: 12px;
    }
}


@media (max-width: 568px) {
    .items-blog-tab-heading .items-blog-tab-menu li {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .header-main .top-bar-right .custom {
        text-align: right;
        float: right;
        margin-bottom: 0 !important;
    }
    .settings-tab .tabs-menu li {
        width: 100% !important;
    }
    .top-bar .top-bar-right {
        float: none !important;
        text-align: center;
        display: block !important;
        margin: 0 auto;
    }
    .header-main .top-bar-right .custom li {
        margin-bottom: 0;
    }
    .top-bar .top-bar-left .socials li {
        border-left: 0 !important;
    }
    .header-main .top-bar-right .custom li {
        border-left: 0 !important;
        &:last-child {
            border-right: 0 !important;
        }
    }
    .item2-gl-nav {
        display: block !important;
        .item2-gl-menu li {
            text-align: center;
            margin-bottom: 10px;
            margin: 0 auto;
            padding: 10px 0;
        }
        label {
            display: block;
            float: none;
            text-align: center;
        }
    }
    .header-main .top-bar-right .custom li a span {
        display: none;
    }
    .top-bar .top-bar-left .socials li {
        float: left !important;
        margin: 0 !important;
    }
    .item2-gl {
        .item2-gl-nav select {
            width: 100% !important;
        }
        .item-card9-desc span {
            display: block;
            margin-bottom: 0.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .users-list {
        .page-header {
            padding: 10px !important;
        }
        .page-select .select2.select2-container {
            width: 100% !important;
            margin-bottom: 0.5rem;
        }
    }
}

@media (min-width: 569px) and (max-width: 992px) {
    .top-bar .top-bar-left .socials li:last-child {
        border-right: 1px solid #e4e6f1;
    }
}

@media (min-width: 481px) and (max-width: 992px) {
    .banner1 .header-text {
        h2 span,
        h3 span {
            padding: 0 !important;
        }
        top: 20% !important;
    }
    .item-card:hover .item-card-btn {
        left: 42%;
    }
    .banner-2 img {
        height: 33rem;
    }
    .item-search-tabs {
        margin-top: 1rem !important;
    }

    .header-search .header-search-logo {
        margin-top: 5px;
    }
    .header-main .post-btn {
        margin-top: 3px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .item-card .item-card-img img,
    .item-card2-img img,
    .item-card9-img img,
    .product-info .product-info-img img,
    .item-card4-img img,
    .item-card5-img img,
    .item-card7-img img,
    .item-card8-img img,
    .overview .overview-img img,

    .header-main .social-icons {
        text-align: center;
        float: none !important;
        li:last-child {
            margin-right: 0;
        }
    }

    .top-bar {
        text-align: center;
        .top-bar-left {
            text-align: center;
            .contact {
                border-left: 0 !important;
                margin-left: 0 !important;
                padding-left: 0 !important;
            }
        }
        .top-bar-right {
            float: none !important;
            text-align: center;
            display: block !important;
            margin: 0 auto;
        }
        .top-bar-left .contact li:first-child {
            margin-right: 1.5rem !important;
        }
    }

    .banner-2 img {
        height: 27rem;
    }
    .items-blog-tab-heading .items-blog-tab-menu li {
        text-align: center;
        margin-bottom: 10px;
    }
    .header-main .top-bar-right .custom {
        display: -webkit-inline-box !important;
    }
    .icon-card li {
        font-size: 10px;
    }
    .header-main .post-btn {
        margin-top: 5px;
    }
}

@media (max-width: 480px) {
    .item-all-card img.imag-service {
        width: 20% !important;
    }
    .section-title h1 {
        font-size: 2rem;
    }

    .banner1 .header-text {
        h2 {
            span {
                padding: 0 !important;
            }
            font-size: 18px !important;
        }
        h3 {
            font-size: 14px !important;
            span {
                padding: 0 !important;
            }
        }
    }
    .card-blogs .card-item-desc.p-0 .card-item-wrap .footer-wrap-price {
        del {
            display: none;
        }
        span.h5 {
            font-size: 12px !important;
        }
    }

    .header-search .header-icons .header-icons-link1 .main-badge1 {
        top: -19px !important;
    }

    .banner-1 {
        height: 500px;
    }
    .item-card7-desc ul li,
    .item-card2-desc ul li {
        font-size: 12px;
    }
    .banner-2 img {
        height: 33rem;
    }
    .item-search-tabs {
        margin-top: 1rem !important;
    }
    .bannerimg .header-text {
        font-size: 1.5rem;
        .breadcrumb-item {
            font-size: 14px;
        }
    }
    .icons a {
        margin-bottom: .5rem;
    }
    .item-det ul li {
        font-size: 11px;
        margin-right: 0.5rem !important;
    }
    .product-slider #thumbcarousel .carousel-item .thumb {
        max-width: 60px !important;
    }
}

@media (max-width: 992px) {
    .horizontal-header .container {
        position: relative;
    }
}

.spacing {
    padding: 1.5rem;
}

.search-background,
.search1 {
    background: $white;
    border-radius: 2px;
    overflow: hidden;
}
.page-header .page-select {
    width: 20%;
}

.social li {
    float: left;
    margin-right: 15px;
    display: inline-block;
    list-style: none;
    font-size: 15px;
    float: right;
}

.social li a {
    color: $white-6;
    line-height: 0;
}

@media (max-width: 480px) {

    .sptb-2 {
        padding-top: 0 !important;
        padding-bottom: 3rem !important;
    }
    .sptb-tab.sptb-2.pt-10 {
        padding-top: 6.5rem !important;
    }
    .banner-2.sptb-2 {
        padding-top: 0 !important;
        padding-bottom: 7rem !important;
    }
    .banner-section .header-text {
        top: 50px !important;
    }
}

@media (max-width: 1300px) and (min-width: 400px) {
    .item-search-menu ul li a {
        display: block;
        margin: 3px;
        border-radius: 2px;
    }
}

.sptb {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.sptb-1 {
    padding-top: 8.5rem;
    padding-bottom: 4.5rem;
}

.sptb-2 {
    padding-top: 4rem;
    padding-bottom: 10rem;
}

.sptb-3,
.sptb-4 {
    padding-top: 6.2rem;
    padding-bottom: 11rem;
}

.sptb-8 {
    padding-top: 7rem;
    padding-bottom: 12rem;
}

.section-title {
    p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 0;
    }
    padding-bottom: 2rem;
    h1 {
        margin-bottom: .5rem;
        color: $color;
        font-size: 36px;
        font-weight: 400;
        z-index: 1;
    }
}

@media (min-width: 992px) {
    .select2-container .select2-selection--single {
        border-radius: 0 !important;
    }
    .slider-images img {
        height: 440px !important;
        background: cover;
        left: 0;
        right: 0;
        width: 100% !important;
    }
    .banner1 .slider .header-text {
        top: 29%;
    }
    .input-field {
        margin-top: 7.55rem !important;
    }
    .sticky-wrapper.is-sticky .horizontal-main {
        .horizontalMenu>.horizontalMenu-list>li {
            padding: 0.75rem 0;
        }
        .btn {
            margin-top: 12px;
        }
    }
    .horizontal-main .btn {
        margin-top: 8px;
    }
}

@media (max-width: 991px) {
    .select2-container .select2-selection--single {
        border-radius: 2px !important;
    }
    .sptb-4,
    .sptb-3 {
        padding-top: 0rem;
        padding-bottom: 10rem;
    }
    .banner-1 .item-search-tabs .search-background .form-control {
        border-radius: 2px !important;
        border-bottom: 1px !important;
    }
    .item-card9-img {
        height: auto !important;
    }
    .blog-list-1 {
        height: 100% !important;
    }
    .banner-1 .search-background {
        .form-control {
            border-radius: 2px !important;
        }
        .form1 {
            border-radius: 0;
            border-left: 1px solid #e4e6f1 !important;
        }
    }
    .item-search-tabs a.btn {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
        border-radius: 2px !important;
        right: 0;
    }
    .content-text .display-5 {
        font-size: 1.8rem;
    }
    .counter-status.status {
        margin-top: 1.5rem;
    }
    .margin-top {
        margin-top: 4.5rem !important;
    }
    .desktoplogo-1 {
        display: none;
    }
    .sptb-1 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }
    .search-background {
        background: 0 0 !important;
        border-radius: 0;
    }
    .padding-bottom {
        padding-bottom: 0 !important;
    }
    .banner-2 .form-control,
    .item-search-tabs .form-control.border {
        border-radius: 2px !important;
    }
    .banner-1 .search-background .btn-lg {
        border-radius: 2px !important;
        right: 0 !important;
    }
    .banner1 .slide-header-text {
        top: 8.5% !important;
    }
    .sptb-2 {
        padding-top: 0;
        padding-bottom: 9rem;
    }
    .sticky-wrapper.is-sticky {
        .horizontal-main {
            background: 0 0;
        }
        .desktoplogo-1 {
            display: none !important;
        }
    }
    .bannerimg {
        padding: 3rem 0 3rem 0 !important;
        background-size: cover;
    }
    .sticky-wrapper {
        height: 0 !important;
    }
    .select2.select2-container.select2-container--default.select2-container--focus {
        width: 100% !important;
        overflow: hidden;
    }
    .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: #e67605;
        color: $white;
    }
    .banner1.relative.slider-images .owl-carousel .owl-item img {
        display: block;
        height: 450px;
    }
}

.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
    color: $color !important;
}

.sticky.sticky-pin.sticky-wrapper.absolute.banners .horizontal-main {
    background: $white !important;
}

.sticky-wrapper.absolute.banners {
    .horizontal-main {
        .horizontalMenu>.horizontalMenu-list>li>a {
            color: $color !important;
            >.fa {
                color: $color !important;
            }
        }
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            border-top: 0;
            background: #c49cde;
            border-radius: 2px;
            z-index: 3;
            width: 100%;
            bottom: 0;
            background-image: -moz-linear-gradient(to right, #4801ff, #a36bc7 12.5%, #20c981 12.5%, #2dce89 25%, #e67605 25%, #fecf71 37.5%, #e45a4d 37.5%, #f8b5ae 50%, #e67605 50%, #fa74a1 62.5%, #8b51b0 62.5%, #c49cde 75%, #1874f0 75%, #92bbf4 87.5%, #1d8eb6 87.5%, #89d3ee);
        }
    }
    .horizontalMenu>.horizontalMenu-list>li {
        &:hover>a,
        >a.active {
            background-color: #e67605;
            color: $white !important;
            .fa {
                color: $white !important;
            }
        }
    }
}

.banner-2 {
    .form-control {
        border-radius: 0;
    }
    .header-text {
        left: 0;
        right: 0;
        color: $white;
        p {
            margin-bottom: 0;
            font-size: 16px;
        }
    }
}

.bannerimg {
    padding: 6.5rem 0 3rem 0;
    background-size: cover;
    .header-text h1 {
        margin-bottom: .75rem;
        font-size: 2.5rem;
    }
}

.about-1 {
    position: relative;
    .header-text {
        left: 0;
        right: 0;
        color: $white;
    }
}

.country .dropdown-menu {
    height: 233px;
    overflow: scroll;
}

.img-flag {
    width: 25px;
    height: 12px;
    margin-top: -4px;
}

.fade.in {
    opacity: 1;
}

.owl-carousel {
    position: relative;
    .owl-item {
        position: relative;
        overflow: hidden;
    }
}

.owl-nav {
    display: block;
}

.slider .owl-nav {
    .owl-prev {
        left: 15px;
    }
    .owl-next {
        right: 15px;
    }
}

@media (max-width: 767px) {
    .icon-card li {
        &.mb-0 {
            &:last-child {
                margin-bottom: 0 !important;
            }
            margin-bottom: 0.5rem !important;
        }
        width: 100% !important;
    }
    .item-cards7-ic li {
        width: 100% !important;
    }
    .item-card7-desc ul.d-flex li {
        margin-bottom: 10px;
        width: 100% !important;
    }
    .counter-status.status-1 {
        margin-top: 1.5rem;
    }
    .item-card7-desc ul.d-flex {
        display: block !important;
    }
    .tabs-menu ul.eductaional-tabs li a {
        display: block;
    }
    .item-card2-desc ul.d-flex li {
        margin-bottom: 10px;
    }
    .item7-card-desc.d-flex,
    .item-card2-desc ul.d-flex {
        display: block !important;
    }
    .item2-gl .item-card9-img.doctor-details img {
        height: auto !important;
    }
}

.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
    background: #fb9512;
}

.profile-pic {
    text-align: center;
    .d-md-flex {
        text-align: left;
    }
}

:focus {
    outline: 0 !important;
}

.register-right .nav-tabs {
    .nav-link {
        padding: 12px 25px;
        margin: 1px;
        text-align: center;
        display: block;
        border-radius: 2px;
        border: 1px solid transparent;
    }
    background: $white;
    margin: 0 auto;
    border-radius: 2px;
    border: 1px solid #e4e6f1;
    box-shadow: none;
    .nav-item {
        display: block;
        text-align: center;
    }
}

.banner-section .header-text {
    position: relative;
    z-index: 10;
    top: 70px;
    bottom: 70px;
    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 2.5rem;
    }
}

.section-title h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
}

.pt-10 {
    padding-top: 8rem !important;
}

.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
    background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
    >a {
        color: $color;
        &.btn {
            color: $white;
            font-size: .85rem;
        }
        >.fa,
        &.active .fa {
            color: $white;
        }
    }
    &:hover>a>.fa {
        color: $white;
    }
    > {
        a:hover .fa {
            color: $white;
        }
        ul.sub-menu>li a i {
            color: #333;
        }
    }
}

.fa-1x {
    font-size: 1.5em;
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}

.info .counter-icon {
    border: 1px solid $black-1 !important;
    background: $black-3;
}

@media (min-width: 411px) and (max-width: 414px) {
    .banner-1 {
        height: 490px !important;
    }
}

.blog-list .item7-card-img {
    height: 196px !important;
}

.blog-list-1 {
    height: 196px;
}

@media screen and (max-width: 1279px) {
    .blog-list .item7-card-img {
        height: 100% !important;
    }
}

.features-desc span {
    width: 50%;
    margin-bottom: 0.5rem;
    float: left;
    margin-right: 0.5rem;
}


/*call-button*/

.call-btn {
    .call-number {
        display: none;
    }
    &.number-btn {
        .call-btn-1 {
            display: none;
        }
        .call-number {
            display: block !important;
        }
    }
}

.sticky-wrapper {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
}

.section-bg {
    background: $white;
}

@media (max-width: 1366px) and (min-width: 993px) {
    .horizontalMenu>.horizontalMenu-list>li>a {
        font-size: 13px;
    }
}

.client-img {
    background: $white;
    border: 1px solid #e4e6f1;
    padding: 5px;
    border-radius: 2px;
}

@media (min-width: 992px) {
    .slider-images {
        .select2-lg .select2-container .select2-selection--single,
        .form-control.input-lg {
            border-left: 1px solid #e4e6f1 !important;
            border-top: none !important;
            border-bottom: none !important;
            border-radius: 0 !important;
        }
    }

    .search-background.bg-transparent {
        .select2-lg .select2-container .select2-selection--single,
        .form-control.input-lg {
            border-right: 0 !important;
            border-top: none !important;
            border-bottom: none !important;
            border-radius: 0 !important;
        }
    }

    .index-search-select .select2.select2-container .select2-selection--single {
        border-right: 0 !important;
        border-top: none !important;
        border-bottom: none !important;
        border-radius: 0 !important;
    }
}

@media (max-width: 767px) {
    .sptb {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

.content-text h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: 400;
}

.previous.list-inline-item.disabled {
    opacity: 0.3;
}

.blog-list .item7-card-img {
    height: 196px;
}

@media (max-width: 1279px) {
    .blog-list .item7-card-img {
        height: 100%;
    }
}

.tab-content.card-body.border {
    padding: 1.5rem 1.5rem;
}
.card-body.border {
    padding: 1.5rem 1.5rem;
}
.list-inline-item .border {
    padding: 0;
}

.btn-default {
    background: #dde2ef;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    padding: .52rem 15px;
    color: #6a7798;
    border-radius: 0;
}

.badge-dark {
    background-color: #343a40;
    color: #fff;
}
.overflow-hidden {
    overflow: hidden;
}

.support-service {
    padding: 1.5rem 1.5rem;
    border: 1px solid #e4e6f1;
    background: #f2f3f8;
    i {
        float: left;
        margin-right: 15px;
        font-size: 1.5rem;
        line-height: 40px;
        width: 45px;
        text-align: center;
        height: 45px;
        border-radius: 50%;
        background: $white-3;
        border: 1px solid transparent;
    }
    h6 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        color: $white-5;
    }
}


.page-main {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.page-content {
    margin: .75rem 0;
}

@media (min-width: 768px) {
    .page-content {
        margin: 1.5rem 0 !important;
    }
}

.page-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    position: relative;
    min-height: 50px;
    background: $white;
}

.users-list .page-header {
    background: 0 0;
    padding: 0;
    border: 0;
}

.bannerimg .breadcrumb {
    display: inline-flex;
}

.page-title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.5rem;
}

.page-title-icon {
    color: #6e82a9;
    font-size: 1.25rem;
}

.page-subtitle {
    font-size: .8125rem;
    color: #5f6877;
    position: relative;
    top: 19px;
    right: 59rem;
    a {
        color: inherit;
    }
}

.page-options {
    margin-left: auto;
}

.page-description {
    margin: .25rem 0 0;
    color: #5f6877;
    a {
        color: inherit;
    }
}

.page-single {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
