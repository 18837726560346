.background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	z-index: 0;
}

.bg-success {
	background-color: #01d277 !important;
}

a.bg-success {
	&:focus,
	&:hover {
		background-color: #3adfab !important;
	}
}

button.bg-success {
	&:focus,
	&:hover {
		background-color: #3adfab !important;
	}
}

.bg-info {
	background-color: #00d6e6 !important;
}

a.bg-info {
	&:focus,
	&:hover {
		background-color: #1594ef !important;
	}
}

button.bg-info {
	&:focus,
	&:hover {
		background-color: #1594ef !important;
	}
}

.bg-warning {
	background-color: $yellow !important;
	color: $white;
}

a.bg-warning {
	&:focus,
	&:hover {
		background-color: #fa3 !important;
	}
}

button.bg-warning {
	&:focus,
	&:hover {
		background-color: #fa3 !important;
	}
}

.bg-danger {
	background-color: $danger !important;
}

a.bg-danger {
	&:focus,
	&:hover {
		background-color: #fb5b50 !important;
	}
}

button.bg-danger {
	&:focus,
	&:hover {
		background-color: #fb5b50 !important;
	}
}

.bg-light {
	background-color: #eff0f9 !important;
}

a.bg-light {
	&:focus,
	&:hover {
		background-color: #eff0f9 !important;
	}
}

button.bg-light {
	&:focus,
	&:hover {
		background-color: #eff0f9 !important;
	}
}

.bg-dark {
	background-color: $black !important;
}

a.bg-dark {
	&:focus,
	&:hover {
		background-color: $black !important;
	}
}

button.bg-dark {
	&:focus,
	&:hover {
		background-color: $black !important;
	}
}

.bg-white {
	background-color: $white !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.bg-red {
	background-color: $danger !important;
	color: $white !important;
}

a.bg-red {
	&:focus,
	&:hover {
		background-color: #fb5b50 !important;
	}
}

button.bg-red {
	&:focus,
	&:hover {
		background-color: #fb5b50 !important;
	}
}

.bg-orange {
	background-color: #e67605 !important;
	color: $white !important;
}

a.bg-orange {
	&:focus,
	&:hover {
		background-color: #fc7a12 !important;
	}
}

button.bg-orange {
	&:focus,
	&:hover {
		background-color: #fc7a12 !important;
	}
}

.bg-yellow {
	background-color: $yellow !important;
	color: $white !important;
}

a.bg-yellow {
	&:focus,
	&:hover {
		background-color: #fa3 !important;
	}
}

button.bg-yellow {
	&:focus,
	&:hover {
		background-color: #fa3 !important;
	}
}

.bg-green {
	background-color: $success !important;
	color: $black !important;
}

a.bg-green {
	&:focus,
	&:hover {
		background-color: #3adfab !important;
	}
}

button.bg-green {
	&:focus,
	&:hover {
		background-color: #3adfab !important;
	}
}

.bg-gray {
	background-color: #868e96 !important;
}

a.bg-gray {
	&:focus,
	&:hover {
		background-color: $color !important;
	}
}

button.bg-gray {
	&:focus,
	&:hover {
		background-color: $color !important;
	}
}


.box-shadow {
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

.bg-background:before,
.bg-background-color:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	display: block;
	z-index: 0;
	top: 0;
}

.bg-background-color .content-text {
	position: relative;
	z-index: 10;
}
